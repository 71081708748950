import { isDefined } from "@clipboard-health/util-ts";
import { Stack } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { useEffect, useRef, useState } from "react";

import { invalidateShifts } from "../../invalidateShifts";
import { VirtualShiftListContextProvider } from "../../Shift/Open/useVirtualShiftListContext/useVirtualShiftListContext";
import { ClearFiltersButton } from "../Filters/ClearFiltersButton";
import { ShiftDiscoverySearchModeSwitcher } from "../SearchModeSwitcher";
import { ListViewPageOpenShiftsList } from "./List";
import { ListViewPageHeader } from "./PageHeader";
import { PullToRefresh } from "./PullToRefresh";
import { useFilteredListViewOpenShiftsData } from "./useFilteredListViewOpenShiftsData";
import { useListViewWorkerShiftsData } from "./useListViewWorkerShiftsData";

export function ShiftDiscoveryListViewPage() {
  const queryClient = useQueryClient();

  const {
    data: {
      filteredOpenShifts,
      unfilteredOpenShifts,
      workplacesMap,
      priorityShiftsCount,
      urgentShiftsCount,
      timeSlotFilters,
    },
    isLoading: openShiftsIsLoading,
    isSuccess: openShiftsIsSuccess,
    isError: openShiftsIsError,
    pagination,
  } = useFilteredListViewOpenShiftsData();

  const { filteredWorkerShifts } = useListViewWorkerShiftsData();

  const filteredShifts = [...filteredWorkerShifts, ...filteredOpenShifts];

  const stackRef = useRef<HTMLDivElement>(null);

  const [shouldInfiniteScroll, setShouldInfiniteScroll] = useState<boolean>(false);

  useEffect(() => {
    const handleScroll = () => {
      // To prevent an infinite loop of infinite scrolling at idle, we should
      // allow infinite scrolling only when the user has scrolled to any amount
      if ((stackRef.current?.scrollTop ?? 0) > 0) {
        if (!shouldInfiniteScroll) {
          setShouldInfiniteScroll(true);
        }
      } else if (shouldInfiniteScroll) {
        setShouldInfiniteScroll(false);
      }
    };

    const element = stackRef.current;

    if (isDefined(element)) {
      element.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (isDefined(element)) {
        element.removeEventListener("scroll", handleScroll);
      }
    };
  }, [shouldInfiniteScroll]);

  return (
    <Stack
      sx={(theme) => ({
        flex: 1,
        backgroundColor: theme.palette.background.primary,
        overflow: "hidden",
        position: "relative",
      })}
    >
      <VirtualShiftListContextProvider
        timeSlotFilters={timeSlotFilters}
        shifts={filteredShifts}
        workplacesMap={workplacesMap}
      >
        <ListViewPageHeader
          priorityShiftsCount={priorityShiftsCount}
          urgentShiftsCount={urgentShiftsCount}
        />

        <PullToRefresh
          scrollRef={stackRef}
          onRefresh={async () => {
            await invalidateShifts(queryClient);
          }}
        />

        <Stack
          ref={stackRef}
          data-testid="list-view-page-scroll-container"
          sx={{
            px: 5,
            overflow: "auto",
            flex: 1,
          }}
        >
          <ListViewPageOpenShiftsList
            isEmpty={filteredShifts.length === 0}
            isError={openShiftsIsError}
            // We show open shifts even if worker shifts are still loading, since:
            // 1. Worker shifts usually load faster
            // 2. Open shifts are the primary content users want to see
            isLoading={openShiftsIsLoading}
            isSuccess={openShiftsIsSuccess}
            workplacesMap={workplacesMap}
            scrollRef={stackRef}
            loadMore={() => {
              void pagination?.fetchNextPage();
            }}
            canLoadMore={Boolean(pagination && shouldInfiniteScroll && pagination?.hasNextPage)}
            isLoadingMore={pagination?.isFetchingNextPage ?? false}
          />
        </Stack>

        <ShiftDiscoverySearchModeSwitcher>
          <ClearFiltersButton allOpenShiftsCount={unfilteredOpenShifts.length} />
        </ShiftDiscoverySearchModeSwitcher>
      </VirtualShiftListContextProvider>
    </Stack>
  );
}
