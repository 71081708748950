import { filterOpenShifts } from "../Filters/filterOpenShifts";
import { useShiftDiscoveryUserFiltersContext } from "../Filters/useUserFiltersContext";
import { isPriorityShift } from "../PriorityShifts/isPriorityShift";
import { useUrgentShiftsData } from "../UrgentShifts/useUrgentShiftsData";
import { useListViewOpenShiftsData } from "./useListViewOpenShiftsData";

export function useFilteredListViewOpenShiftsData() {
  const {
    distance,
    // Filtering by timeslot happens at the list rendering level
    // because each date can have a different set of timeslots
    shiftTimeSlots: timeSlotFilters,
    ...localUserFilters
  } = useShiftDiscoveryUserFiltersContext();

  const {
    shifts = [],
    workplacesMap = new Map(),
    ...openShiftsDataResult
  } = useListViewOpenShiftsData({ distance });

  // The open shifts data doesn't include all urgent shifts, so we need to fetch them separately
  const { shifts: urgentShifts } = useUrgentShiftsData();

  const priorityShifts = shifts.filter((shift) => isPriorityShift(shift));

  return {
    data: {
      filteredOpenShifts: filterOpenShifts({ shifts }, localUserFilters),
      unfilteredOpenShifts: shifts,
      workplacesMap,
      urgentShiftsCount: urgentShifts.length,
      priorityShiftsCount: priorityShifts.length,
      timeSlotFilters,
    },
    ...openShiftsDataResult,
  };
}
