import { Stack } from "@mui/material";

import { APP_V2_USER_EVENTS, logEvent } from "../../../lib/analytics";
import { Badge } from "../../components/Badge";
import { IconButton } from "../../components/IconButton";
import { PageHeaderWrapper } from "../../components/PageHeaderWrapper";
import { PickerButton } from "../../components/PickerButton";
import { getTimeSlotLabel } from "../../Shift/getTimeSlotLabel";
import { ShiftDiscoveryCalloutsContainer } from "../CalloutsContainer";
import { useShiftDiscoveryUserFiltersContext } from "../Filters/useUserFiltersContext";
import { SHIFT_DISCOVERY_CALENDAR_PATH, SHIFT_DISCOVERY_FILTERS_PATH } from "../paths";
import { useShiftModalsDataContext } from "../useShiftModalsDataContext";

interface ListViewPageHeaderProps {
  priorityShiftsCount: number;
  urgentShiftsCount: number;
}

export function ListViewPageHeader(props: ListViewPageHeaderProps) {
  const { priorityShiftsCount, urgentShiftsCount } = props;

  const { navigateToModal } = useShiftModalsDataContext();

  const { shiftTimeSlots, dates, appliedFiltersCount } = useShiftDiscoveryUserFiltersContext();

  return (
    <PageHeaderWrapper variant="tertiary" sx={{ paddingBottom: 1 }}>
      <Stack direction="row" sx={{ paddingBottom: 3, px: 5 }} spacing={4}>
        <PickerButton
          fullWidth
          size="medium"
          aria-label="Calendar View Navigation"
          primaryText={dates.length === 0 ? "Calendar" : `${dates.length} dates filtered`}
          secondaryText={
            shiftTimeSlots.length === 0 || shiftTimeSlots.length > 2
              ? "All time slots"
              : shiftTimeSlots.map((slot) => getTimeSlotLabel(slot)).join(", ")
          }
          startIconType="calendar-navigation"
          isSelected={dates.length > 0}
          onClick={() => {
            logEvent(APP_V2_USER_EVENTS.OPEN_SHIFTS_CALENDAR_OPENED);
            navigateToModal(SHIFT_DISCOVERY_CALENDAR_PATH);
          }}
        />

        <Badge
          count={appliedFiltersCount}
          overlap="circular"
          color="primary"
          anchorOrigin={{ vertical: "top", horizontal: "left" }}
        >
          <IconButton
            variant="outlinedHeavy"
            aria-label="Open Filters Button"
            size="medium"
            iconType="slider-settings"
            isSelected={appliedFiltersCount > 0}
            onClick={() => {
              logEvent(APP_V2_USER_EVENTS.OPEN_SHIFTS_FILTERS_VIEWED);
              navigateToModal(SHIFT_DISCOVERY_FILTERS_PATH);
            }}
          />
        </Badge>
      </Stack>

      <ShiftDiscoveryCalloutsContainer
        priorityShiftsCount={priorityShiftsCount}
        urgentShiftsCount={urgentShiftsCount}
        sx={{ px: 5, paddingTop: 1, paddingBottom: 3 }}
      />
    </PageHeaderWrapper>
  );
}
