/* eslint-disable import/max-dependencies */
import { Text } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import { useGetMissingRequirementsForWorkplaces } from "@src/appV2/Accounts/Documents/api/useGetMissingRequirements";
import { APP_V2_USER_EVENTS } from "@src/appV2/lib/analytics";
import { logEvent } from "@src/appV2/lib/analytics/log";
import { convertToGeoLocation } from "@src/appV2/Location";
import { useDefinedWorker } from "@src/appV2/Worker/useDefinedWorker";
import { parseISO } from "date-fns";
import { useEffect } from "react";
import { Virtuoso } from "react-virtuoso";

import { getMissingAndPendingDocumentsForQualification } from "../../Shift/Bookability/Documents/getMissingAndPendingDocumentsForQualification";
import { convertOpenShiftToShiftWithType } from "../../Shift/convertOpenShiftToShiftWithType";
import { OpenShiftCard } from "../../Shift/Open/Card";
import { ShiftListDateHeader } from "../../Shift/Open/ListDateHeader";
import { ShiftListFooter } from "../../Shift/Open/ListFooter";
import { ShiftListItemWrapper } from "../../Shift/Open/ListItemWrapper";
import { OpenShiftsListLoadingState } from "../../Shift/Open/ListLoadingState";
import { type OpenShiftWorkplace } from "../../Shift/Open/types";
import { useVirtualShiftListContext } from "../../Shift/Open/useVirtualShiftListContext/useVirtualShiftListContext";
import { OpenShiftsListWorkerShiftCard } from "../../Shift/Open/WorkerCard";
import { formatDayOfMonthWithDayName } from "../../utils/formatDayOfMonthWithDayName";
import { useShiftDiscoveryUserFiltersContext } from "../Filters/useUserFiltersContext";
import { SHIFT_DISCOVERY_SHIFT_MODAL_PATH } from "../paths";
import { useShiftModalsDataContext } from "../useShiftModalsDataContext";
import { ListViewPageOpenShiftsEmptyState } from "./ListEmptyState";

interface ListViewPageOpenShiftsListProps {
  isEmpty?: boolean;
  isError: boolean;
  isLoading: boolean;
  isSuccess: boolean;
  workplacesMap: Map<string, OpenShiftWorkplace>;
  scrollRef: React.RefObject<HTMLDivElement>;
  loadMore: () => void;
  canLoadMore: boolean;
  isLoadingMore: boolean;
}

/**
 * Shows the list of open shifts and worker shifts for the current date
 * We prioritize showing open shifts and don't wait for worker shifts to load since:
 * 1. Worker shifts typically load faster than open shifts
 * 2. Open shifts are the primary content users want to see
 * Therefore we don't show loading/error states for worker shifts
 */
export function ListViewPageOpenShiftsList(props: ListViewPageOpenShiftsListProps) {
  const {
    isEmpty,
    isError,
    isLoading,
    isSuccess,
    workplacesMap,
    scrollRef,
    loadMore,
    canLoadMore,
    isLoadingMore,
  } = props;

  const { listRef, virtualShiftListItems, scrollToDate, onGroupHeaderTimeSlotClick } =
    useVirtualShiftListContext();

  const { navigateToModal, setInitialShiftData } = useShiftModalsDataContext();
  const { userId: workerId, licensesData } = useDefinedWorker();
  const { pickedSingleDate, setPickedSingleDate } = useShiftDiscoveryUserFiltersContext();

  const missingRequirements = useGetMissingRequirementsForWorkplaces(
    workerId,
    Array.from(workplacesMap.values(), (workplace) => ({
      id: workplace.id,
      type: workplace.attributes.type,
      msa: workplace.attributes.location.msa,
      state: workplace.attributes.location.state,
      region: workplace.attributes.location.region,
    })),
    licensesData?.map((l) => l.qualification),
    {
      enabled: !isEmpty,
    }
  );

  useEffect(() => {
    if (isDefined(pickedSingleDate)) {
      scrollToDate(pickedSingleDate);
      setPickedSingleDate(undefined);
    }
  }, [pickedSingleDate, scrollToDate, setPickedSingleDate]);

  if (isError) {
    return <Text>Error loading shifts</Text>;
  }

  if (isLoading) {
    return (
      <OpenShiftsListLoadingState
        texts={[
          "Finding you the best-paying shifts nearby",
          "Analyzing shift opportunities just for you",
          "Your perfect shift is just a moment away",
          "Crunching the numbers to maximize your earnings",
          "Digging deep into facility schedules",
          "Searching high and low for the best shifts nearby",
          "We are on it-finding shifts that matter most",
          "Unlocking your next great opportunity",
        ]}
      />
    );
  }

  if (isSuccess && isEmpty) {
    return <ListViewPageOpenShiftsEmptyState />;
  }

  return (
    <Virtuoso
      ref={listRef}
      data={virtualShiftListItems}
      customScrollParent={scrollRef.current ?? undefined}
      width="100%"
      increaseViewportBy={300}
      topItemCount={0}
      endReached={() => {
        if (canLoadMore) {
          loadMore();
        }
      }}
      components={{
        Item: ShiftListItemWrapper,
        Footer: () => <ShiftListFooter isLoadingMore={isLoadingMore} />,
      }}
      itemContent={(index, item) => {
        if (item.type === "group-header") {
          const date = parseISO(item.dateIso);
          const label = formatDayOfMonthWithDayName(date);
          return (
            <ShiftListDateHeader
              label={label}
              totalShiftsCount={item.totalShiftsCount}
              shiftsByTimeSlotBreakdown={item.shiftsByTimeSlotBreakdown}
              timeSlotFilters={item.visibleTimeSlots}
              showDivider={index !== 0}
              onTimeSlotClick={(timeSlot) => {
                logEvent(APP_V2_USER_EVENTS.SHIFT_LIST_TIME_SLOT_CLICKED, {
                  trigger: "ListView",
                  timeSlot,
                  date: label,
                  timeSlotFilters: item.visibleTimeSlots,
                  shiftsByTimeSlotBreakdown: item.shiftsByTimeSlotBreakdown,
                });

                onGroupHeaderTimeSlotClick(item, timeSlot);
              }}
            />
          );
        }

        if ("agent" in item) {
          return (
            <OpenShiftsListWorkerShiftCard
              shiftId={item._id}
              workplaceName={item.facility.name}
              workplaceTimezone={item.facility.tmz}
              workplaceGeoLocation={convertToGeoLocation(item.facility.geoLocation.coordinates)}
              shiftStartIso={item.start}
              shiftEndIso={item.end}
              shiftType={item.type}
              shiftTimeSlot={item.name}
              shiftFinalPay={item.finalPay}
              shiftOriginalAmount={item.originalAmount ?? undefined}
              shiftOfferPay={item.offer?.pay.value}
              qualificationName={item.agentReq}
            />
          );
        }

        const workplace = workplacesMap.get(item.relationships.workplace.data.id);
        if (!isDefined(workplace)) {
          return null;
        }

        const { missingDocuments, pendingDocuments } =
          getMissingAndPendingDocumentsForQualification(
            workplace.id,
            item.attributes.qualification,
            missingRequirements.results
          );

        return (
          <OpenShiftCard
            shift={item}
            workplace={workplace}
            missingDocuments={missingDocuments}
            pendingDocuments={pendingDocuments}
            onClick={(shift, workplace, offer) => {
              logEvent(APP_V2_USER_EVENTS.SHIFT_DETAILS_OPENED, { shiftId: item.id });
              setInitialShiftData(convertOpenShiftToShiftWithType(shift, workplace, offer));
              navigateToModal(SHIFT_DISCOVERY_SHIFT_MODAL_PATH, { shiftId: item.id });
            }}
          />
        );
      }}
    />
  );
}
/* eslint-enable import/max-dependencies */
